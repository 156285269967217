<template>
    <ValidationObserver tag="form" @submit.prevent="onSubmit" ref="observer" class="form_settings">
        <!--                        {% csrf_token %}-->
        <input type='hidden' name="form_type" value="form">
        <div class="row">
            <div class="col-xl-4 col-md-6 col-12">
                <div class="company-logo">
                    <label for="id_image" class="logo__preview">
                        <img
                            v-if="form.image || preview"
                            :src="preview || form.image"
                            alt=""
                            class="logo__img"
                        >
                        <span v-else class="logo__letter">{{form.company[0]}}</span>
                    </label>
                    <div class="logo__wrap">
                        <label for="id_image" class="logo__load v-link">
                            {{!form.image ? 'Загрузить' : 'Изменить'}} логотип
                        </label>
                        <span class="logo__meta">
                            jpeg, jpg, png, svg не более 2 мб
                        </span>
                    </div>
                    <div class="hidden">
                        <input class="hidden" type="file" name="image" accept="image/*" id="id_image" ref="file"
                               @change="handleFileUpload">
                        <input class="hidden" v-model="form.image">
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
                <ValidationProvider vid="company" rules="required" v-slot="provider">
                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material">
                            <input
                                v-model="form.company"
                                type="text" maxlength="255" id="id_company" class="form-control">
                            <label for="id_company">Название компании *</label>
                        </div>
                         <div id="id_company-error" class="invalid-feedback animated fadeInDown"
                                         v-if="provider.errors.length > 0">{{provider.errors[0]}}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-xl-4 col-md-6 col-12" v-if="is_box !== 'true' ">
                <ValidationProvider vid="domain" rules="required" v-slot="provider">
                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material input-group">
                            <input
                                v-model="form.domain"
                                type="text"
                                v-mask="{mask: '*{1,}.wikiworks.io', greedy: false}"
                                maxlength="255"
                                id="id_domain"
                                class="form-control">
                            <label for="id_domain">Адрес портала *</label>
                        </div>
                        <div id="id_domain-error" class="invalid-feedback animated fadeInDown"
                                         v-if="provider.errors.length > 0">{{provider.errors[0]}}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <div class="form-material">
                        <input
                            v-model="form.off_url_company"
                            type="text" maxlength="255" id="id_off_url_company" class="form-control">
                        <label for="id_off_url_company">Сайт компании</label>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <div class="form-material">
                        <input
                            v-model="form.off_email_company"
                            type="email" maxlength="255" id="id_off_email_company" class="form-control">
                        <label for="id_off_email_company">E-mail компании</label>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-12">
                <div class="form-group">
                    <div class="form-material">
                        <input
                            v-mask="{mask: '+7 (999) 999-9999', greedy: false}"
                            v-model="form.off_phone_company"
                            type="text" maxlength="255" id="id_off_phone_company" class="form-control">
                        <label for="id_off_phone_company">Телефон компании</label>
                    </div>
                </div>
            </div>
        </div>
        <button type="submit" class="v-btn" :class="{'v-btn--gray' : isFormsSimilar || !isNewImage}">
            Сохранить
        </button>
    </ValidationObserver>
</template>

<script>
    import session from '@/api/session';
    import validation from "assets/js/validate";
    import { mapState } from 'vuex'
    import CompanyLogo from '@/components/settings/logo/CompanyLogo'
    export default {
        name: "Form",
        components: {
            CompanyLogo
        },
        data() {
            console.log('BOX', process.env.VUE_APP_BOX_VERSION)
            return {
                form: {
                    company: '',
                    domain: '',
                    off_email_company: '',
                    off_phone_company: '',
                    off_url_company: '',
                    image: null
                },
                preview: null,
                formCopy: {},
                isNewImage: false,
                validation,
                is_box: process.env.VUE_APP_BOX_VERSION
            }
        },
        computed: {
            ...mapState('settings', [
                'settingsForm',
            ]),
            isFormsSimilar() {
                return JSON.stringify(this.form) === JSON.stringify(this.formCopy);
            }
        },
        methods: {
            async onSubmit() {
                this.form.domain = this.form.domain.replace('.wikiworks.io', '');
                let isValid = await this.$refs.observer.validate();
                console.log('isValid', isValid)
                if (isValid) {

                    let data = this.form;
                    let config = {};

                    if (this.isNewImage) {
                        let formData = new FormData();
                        config['headers'] = {
                            'Content-Type': 'multipart/form-data'
                        };
                        for (let [key, value] of Object.entries(data)) {
                            if (Array.isArray(value) && value.length) {
                                for (let item of value) {
                                    formData.append(`${key}[]`, item);
                                }
                                continue;
                            }
                            if (!!value) {
                                formData.append(key, value);
                            }
                            data = formData;
                        }
                    } else {
                        delete data.image;
                    }

                    session
                        .put(`/api/v1/settings/1/`, data)
                        .then(response => {
                            if (response.data.url) {
                                window.location.href = response.data.url
                            } else {
                                this.$store.dispatch('sidebar/initialize');
                                this.$swal({
                                    title: 'Отлично!',
                                    text: 'Данные изменены',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            }
                        })
                        .catch((error) => {
                            this.errorHandler(error);
                        });
                }
            },
            async handleFileUpload() {
                let reader = new FileReader(),
                    file = this.$refs.file.files[0];
                reader.addEventListener("load", function () {
                    const CropperModal = () => import(`../modals/CropperModal`);
                    let isValid = this.validation.file(file, ['jpg', 'jpeg', 'png', 'svg'], 2000);
                    this.$refs.file.value = null;
                    if (isValid) {
                        let isSvg = /\.(svg)$/i.test(file.name);
                        if (isSvg) {
                            this.form.image = file;
                            this.preview = URL.createObjectURL(file);
                            this.isNewImage = true;
                        } else {
                            this.$modal.show(CropperModal, {
                                    image: reader.result
                                }, {
                                    name: 'CropperModal',
                                    adaptive: true,
                                    maxWidth: 740,
                                    width: '100%',
                                    height: 'auto',
                                    clickToClose: false,
                                    scrollable: true,
                                    pivotY: 0,
                                    styles: 'margin-top: 8px; margin-bottom: 8px;'
                                }, {
                                    'onSave': data => {
                                        this.form.x = data.x;
                                        this.form.y = data.y;
                                        this.form.width = data.width;
                                        this.form.height = data.height;
                                        this.isNewImage = true;
                                        this.form.image = file;
                                    },
                                    'setPreview': preview => {
                                        this.preview = preview;
                                    }
                                }
                            )
                        }
                    }
                }.bind(this), false);

                if (file) {
                    reader.readAsDataURL(file);
                }
            },
            errorHandler(error) {
                console.log('error', error);
                if (error.response) {
                    this.$refs.observer.setErrors(error.response.data);
                }
            },
        },
        mounted() {
            this.form = { ...this.settingsForm };
            this.formCopy = {
                ...this.settingsForm
            };
        },
    }
</script>

<style lang="scss">
    @import '#sass/v-style';
</style>
